<template lang="html" src="./pageBrands.template.vue"></template>

<style lang="scss" src="./pageBrands.scss"></style>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrands.i18n');

export default {
  name: 'PageBrands',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brands: [],
      brandsById: {},
      headers: [],
      search: '',
      loadingTable: false,
    };
  },
  created() {
    this.getAllBrands();
  },
  mounted() {
    this.headers = [
      { text: this.$t('Type'), value: 'features.startup', sort: this.sortBrandsTypeFn, align: 'center' },
      { text: this.$t('BCMNetwork'), value: 'features.accessToBCMNetwork', sortable: false, align: 'center'},
      { text: this.$t('PrivateSuppliers'), value: 'features.privateSuppliers', sortable: false, align: 'center'},
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('ManagedBy'), value: 'features.startup.managedBy', sort: this.sortManageByFn},
      { text: this.$t('Plan'), value: 'subscription.plan'},
      { text: this.$t('Users'), value: 'userCount', sort: this.sortUsersFn},
      { text: this.$t('created'), value: 'created' },
      { text: this.$t('action'), value: 'action', align: 'center' },
    ];
  },
  methods: {
    sortBrandsTypeFn(s1, s2) {
      if(s1 && s1.isManager) {
        if(!s2) {
          return 1;
        }
        if(s2.isManager) {
          return 0;
        }
        return 1;
      } else if(!s1 || (s1 && !s1.managedBy)) {
        if(!s2) {
          return 0;
        }
        if(s2.isManager) {
          return -1;
        }
        if(!s2.managedBy) {
          return 0;
        }
        return 1;
      } else {
        if(!s2) {
          return -1;
        }
        if(s2.isManager) {
          return -1;
        }
        if(!s2.managedBy) {
          return -1;
        }
        return 0;
      }
    },
    sortManageByFn(id1, id2) {
      if((this.brandsById[id1] || {}).name > (this.brandsById[id2] || {}).name) {
        return 1;
      } else if ((this.brandsById[id1] || {}).name === (this.brandsById[id2] || {}).name) {
        return 0;
      }
      return -1;
    },
    sortUsersFn(r1, r2) {
      if(r1 > r2) {
        return 1;
      } else if(r1 < r2) {
        return -1;
      }
      return 0;
    },
    customFilter(value, search, item) {
      const managedById = item.features.startup ? item.features.startup.managedBy : null;
      return CustomFilterForSearchDatatable.customFilter(value, search, this) || (this.brandsById[managedById] && this.brandsById[managedById].name.toLowerCase().includes(search.toLowerCase()));
    },
    getAllBrands() {
      this.loadingTable = true;
      /**
       * GET ALL BRANDS
       */
      this.$apiInstance
        .getAllBrands()
        .then(
          (data) => {
            for(const brand of data) {
              const users = new Set();
              if(brand.roles) {
                for(const admin of brand.roles.admin) {
                  users.add(admin);
                }
                for(const user of brand.roles.access) {
                  users.add(user);
                }
              }
              brand.userCount = users.size;
              if(!brand.features.startup || (!brand.features.startup.isManager && !brand.features.startup.managedBy)) {
                brand.type = 'Brand';
              } else if(brand.features.startup.isManager) {
                brand.type = 'Manager';
              } else {
                brand.type = 'Startup';
              }
              this.brandsById[brand._id] = brand;
            }
            this.brands = data;
          },
          (error) => {
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTable = false;
        });
    },
    goToPageItem(item) {
      this.$router.push({name: 'BrandParts', params: { brandUUID: `${item._id}`}});
    },
  },
};
</script>
