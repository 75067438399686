var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-brands"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('Brands')))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "to": {
        name: 'BrandAdd'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(" " + _vm._s(_vm.$t("AddNewBrand")) + " ")], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _vm.brands ? _c('v-card', [_c('v-card-title', [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    staticClass: "cursorTable",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.brands,
      "search": _vm.search,
      "loading": _vm.loadingTable,
      "custom-filter": _vm.customFilter,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function ($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.features.startup`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'BrandParts',
              params: {
                brandUUID: `${item._id}`
              }
            }
          }
        }, [_c('div', [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [item.type === 'Brand' ? _c('v-icon', _vm._g({
                attrs: {
                  "small": "",
                  "color": "info"
                }
              }, on), [_vm._v(" fas fa-warehouse ")]) : item.type === 'Manager' ? _c('v-icon', _vm._g({
                attrs: {
                  "small": "",
                  "color": "info"
                }
              }, on), [_vm._v(" fas fa-users-cog ")]) : _c('v-icon', _vm._g({
                attrs: {
                  "small": "",
                  "color": "info"
                }
              }, on), [_vm._v(" fas fa-rocket ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t(item.type)) + " ")])])], 1)])];
      }
    }, {
      key: `item.features.accessToBCMNetwork`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'BrandParts',
              params: {
                brandUUID: `${item._id}`
              }
            }
          }
        }, [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on;
              return [_c('v-img', _vm._g({
                staticClass: "mr-4",
                class: {
                  'img-disabled': !item.features.accessToBCMNetwork
                },
                attrs: {
                  "src": require("../../assets/logo_cut.png"),
                  "max-height": "32px",
                  "contain": ""
                }
              }, on))];
            }
          }], null, true)
        }, [item.features.accessToBCMNetwork ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('BrandHasAccessToBCMNetwork')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('BrandDoesNotHaveAccessToBCMNetwork')) + " ")])])], 1)];
      }
    }, {
      key: `item.features.privateSuppliers`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'BrandParts',
              params: {
                brandUUID: `${item._id}`
              }
            }
          }
        }, [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var on = _ref6.on;
              return [_c('v-icon', _vm._g({
                attrs: {
                  "height": "32px",
                  "color": item.features.privateSuppliers.length ? 'orange' : 'disabled'
                }
              }, on), [_vm._v(" fas fa-industry ")])];
            }
          }], null, true)
        }, [item.features.privateSuppliers.length ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('BrandHasPrivateSuppliers', {
          privateSuppliers: item.features.privateSuppliers.length
        })) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('BrandDoesNotHavePrivateSuppliers')) + " ")])])], 1)];
      }
    }, {
      key: `item.name`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'BrandParts',
              params: {
                brandUUID: `${item._id}`
              }
            },
            "title": item.name
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: `item.features.startup.managedBy`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'BrandParts',
              params: {
                brandUUID: `${item.features.startup && item.features.startup.managedBy ? item.features.startup && item.features.startup.managedBy : item._id}`
              }
            }
          }
        }, [item.features.startup && item.features.startup.managedBy ? _c('span', [_vm._v(" " + _vm._s(_vm.brandsById[item.features.startup.managedBy].name) + " ")]) : _vm._e()])];
      }
    }, {
      key: `item.userCount`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'BrandParts',
              params: {
                brandUUID: `${item._id}`
              }
            }
          }
        }, [_c('span', [_vm._v(" " + _vm._s(item.userCount) + " "), _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref10) {
              var on = _ref10.on;
              return [_c('v-icon', _vm._g({
                staticClass: "ml-2",
                attrs: {
                  "small": "",
                  "color": "info"
                }
              }, on), [_vm._v(" fas fa-user-alt ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Users')) + " ")])])], 1)])];
      }
    }, {
      key: `item.action`,
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "to": {
              name: 'BrandParts',
              params: {
                brandUUID: `${item._id}`
              }
            },
            "title": _vm.$t('view')
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)];
      }
    }, {
      key: `item.created`,
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'BrandParts',
              params: {
                brandUUID: `${item._id}`
              }
            },
            "title": item.created
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long")) + " ")])];
      }
    }], null, true)
  })], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }